import React from "react"
import Navbar from "./Navbar/Navbar"
import Sidebar from "./Sidebar/Sidebar"
import Footer from "./Footer/Footer"

import "normalize.css"
import "../assets/css/main.css"
import "@fontsource/noto-sans-jp/400.css"

const Layout = ({ children }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Sidebar />
      <Navbar />
      {/* <main style={{ flexGrow: 1, backgroundColor: "rgb(40, 40, 40)" }} className="htmlMain"> */}
      <main style={{ flexGrow: 1 }} className="htmlMain">
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
