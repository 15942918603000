import React, { useState } from "react"
import { Link } from "gatsby"
import { FiAlignJustify } from "react-icons/fi"
import Container from "../Container/Container"
import SearchPost from "../SearchPost/SearchPost"

import "./Navbar.css"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className="navbar">
      <Container>
        <div className="nav-center">
          <div className="nav-header">
            <Link to="/" className="nav-logo-link">
              <div className="nav-logo-wrapper">
                <div className="nav-logo">Dev Sec Note</div>
              </div>
            </Link>
            <button className="toggle-btn" onClick={() => setIsOpen(!isOpen)}>
              <FiAlignJustify className="toggle-bar" />
            </button>
          </div>
          <div className="nav-rhs-menu">
            <div className={`nav-links-wrapper${isOpen ? " show-links" : ""}`}>
              <div className="nav-links">
                <Link to="/" className="nav-link nav-link-top">
                  <span>Home</span>
                </Link>
                <Link to="/blog" className="nav-link">
                  <span>Posts</span>
                </Link>
                <Link to="/about" className="nav-link">
                  <span>About</span>
                </Link>
              </div>
            </div>
            <SearchPost />
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default Navbar
