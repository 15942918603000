import React from "react"
import Container from "../Container/Container"

import "./Footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer-items">
          <p className="footer-text">
            &copy;{new Date().getFullYear()} hitohira
          </p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
