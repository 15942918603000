import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container/Container"

import "../assets/css/404.css"

const NotFoundPage = () => (
  <>
    <Layout>
      <Container>
        <section className="error-page-wrapper">
          <h3 className="error-title">404</h3>
          <h3 className="error-text">Page Not Found</h3>
        </section>
      </Container>
    </Layout>
  </>
)

export default NotFoundPage
