import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect, useCallback, useRef } from "react"
import { FaSearch } from "react-icons/fa"
import { Link } from "gatsby"

import "./SearchPost.css"

const SearchPost = () => {
  const [searchData, setSearchData] = useState([])
  const [resultData, setResultData] = useState([])
  const [inputText, setInputText] = useState("")
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const searchRef = useRef()
  const searchMaskRef = useRef()

  // 検索モーダルの表示
  const openSearchModal = () => [setIsSearchOpen(() => true)]

  const closeSearchModal = e => {
    if (!searchMaskRef.current) return
    if (e.target === searchMaskRef.current) setIsSearchOpen(() => false)
  }

  useEffect(() => {
    const searchInput = searchRef.current
    if (!searchInput) return
    searchInput.addEventListener("focus", openSearchModal)
    document.addEventListener("click", closeSearchModal)

    return () => {
      searchInput.removeEventListener("focus", openSearchModal)
      document.removeEventListener("click", closeSearchModal)
    }
  }, [])

  // 検索用に全ての記事を取得
  const allPostsData = useStaticQuery(graphql`
    query MyQuery {
      allMdx(sort: { order: DESC, fields: frontmatter___date }, limit: 1000) {
        nodes {
          excerpt
          frontmatter {
            category
            slug
            title
            date
          }
        }
      }
    }
  `)

  useEffect(() => {
    const allData = []
    allPostsData.allMdx.nodes.forEach(elem => {
      allData.push(elem)
    })
    setSearchData(() => allData)
  }, [allPostsData.allMdx.nodes])

  // 検索結果の表示用のコンテンツを作成する.
  const makeResultElement = (
    post,
    titleStart,
    titleEnd,
    tagStart,
    tagEnd,
    excerptStart,
    excerptEnd
  ) => {
    return (
      <Link
        to={`/posts/${post.frontmatter.slug}`}
        className="search-result-link"
      >
        <div>
          <div className="resultTitle-wrap">
            {titleStart !== -1 ? (
              <>
                {post.frontmatter.title.slice(0, titleStart)}
                <span className="searchHit">
                  {post.frontmatter.title.slice(titleStart, titleEnd)}
                </span>
                {post.frontmatter.title.slice(
                  titleEnd,
                  post.frontmatter.title.length
                )}
              </>
            ) : (
              <>{post.frontmatter.title}</>
            )}

            <span className="resultTag">
              {tagStart !== -1 ? (
                <>
                  {post.frontmatter.category.slice(0, tagStart)}
                  <span className="searchHit">
                    {post.frontmatter.category.slice(tagStart, tagEnd)}
                  </span>
                  {post.frontmatter.category.slice(
                    tagEnd,
                    post.frontmatter.category.length
                  )}
                </>
              ) : (
                <>{post.frontmatter.category}</>
              )}
            </span>
          </div>
          <div className="resultExcerpt">
            {excerptStart !== -1 ? (
              <>
                {" - "}
                {post.excerpt.slice(0, excerptStart)}
                <span className="searchHit">
                  {post.excerpt.slice(excerptStart, excerptEnd)}
                </span>
                {post.excerpt.slice(excerptEnd, post.excerpt.length)}
              </>
            ) : (
              <>{` - ${post.excerpt}`}</>
            )}
          </div>
        </div>
      </Link>
    )
  }

  // 検索処理
  const searchPosts = useCallback(() => {
    const value = inputText.toLowerCase()

    const searchResult = searchData
      .map(post => {
        if (value === "") return null

        const titleTexts = `
                ${post.frontmatter.title.toLowerCase()}
                ${post.frontmatter.category.toLowerCase()}
                ${post.excerpt.toLowerCase()}
            `

        if (titleTexts.indexOf(value) === -1) return null

        const titleStart = post.frontmatter.title.toLowerCase().indexOf(value)
        const titleEnd = titleStart + value.length
        const tagStart = post.frontmatter.category.toLowerCase().indexOf(value)
        const tagEnd = tagStart + value.length
        const excerptStart = post.excerpt.toLowerCase().indexOf(value)
        const excerptEnd = excerptStart + value.length

        return makeResultElement(
          post,
          titleStart,
          titleEnd,
          tagStart,
          tagEnd,
          excerptStart,
          excerptEnd
        )
      })
      .filter(post => post !== null)

    setResultData(() => searchResult)
  }, [searchData, inputText])

  useEffect(() => {
    searchPosts()
  }, [inputText, searchPosts])

  const handleInputText = e => {
    const input = e.target.value

    if (input.length <= 30) {
      setInputText(() => input)
    }
  }

  return (
    <div className="search-wrap">
      <div
        className={`modalMask${isSearchOpen ? " modalMask-active" : ""}`}
        ref={searchMaskRef}
      />
      <div className={`searchBox${isSearchOpen ? " searchBox-active" : ""}`}>
        <input
          type="text"
          placeholder="Search"
          className={`searchInput${isSearchOpen ? " searchInput-active" : ""}`}
          onChange={handleInputText}
          ref={searchRef}
        />
        <FaSearch className="searchIcon" />
        <div
          className={`searchResultCount${
            isSearchOpen ? " searchResultCount-active" : ""
          }`}
        >{`検索結果: ${resultData.length} 件`}</div>
        <div
          className={`searchResult-wrap${
            isSearchOpen ? " searchResult-wrap-active" : ""
          }`}
        >
          {resultData.map((post, index) => {
            return (
              <div
                key={index}
                className={`searchResultRow${
                  isSearchOpen ? " searchResultRow-active" : ""
                }`}
              >
                {post}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SearchPost
